header {
  //padding: 10px;
  .responsive-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 100px;
    width: 100%;
    .img-container,
    .mobile-w-full {
      margin-top: 10px;
      height: 80px;
      .header-img {
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  header {
    padding: 5px;
    .responsive-header {
      height: auto;
      flex-wrap: wrap;
      justify-content: space-around;
      .mobile-w-full {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 100px;
        .header-img {
          margin: auto;
        }
        
        img {
          height: 100%;
        }
      }

      .img-container {
        height: 50px;
      }
    }
  }
}
