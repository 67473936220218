$columns: 12;

@mixin line-clamp-all {
  @for $i from 1 through $columns {
    &.line-clamp-#{$i} {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: $i;
    }
  }
}

@include line-clamp-all;
