footer.footer {
  padding: 3rem 1.5rem 3rem;
  color: $text;
  @extend .is-background-square;
  border-top: 1px solid $border;

  .social-media {
    color: $azul-primary;
    a {
      color: inherit;
      font-size: 2rem;
    }
  }
  h4 {
    text-transform: uppercase;
  }
  .media {
    color: $azul_gov;
    font-size: 0.95rem;
    text-align: left;
    a,
    strong {
      color: inherit;
    }

    .media-left {
      margin-left: 0em;
    }
  }
}

.footer-sly {
  .card {
    min-height: 135px;
    .card-image {
      display: flex;
      vertical-align: middle;
    }
  }
}
