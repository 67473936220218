.flags {
  &.show {
    display: block;
  }
}
.flag {
  top: 27px;
  left: -70px;
  position: absolute;
  width: 100%;
  div {
    position: absolute;
    z-index: 3;
    width: 10px;
    left: 50%;
    margin: 0px;
    padding: 0px;
    height: 18px;
    opacity: 1;
    animation: myfirst 6s linear infinite;
  }
  .rojo {
    background-color: #d83a2c;
  }
  .blanco {
    margin-top: 18px;
    background-color: white;
    animation: myfirst 6s linear 0.1s infinite;
  }
  .azul {
    margin-top: 32px;
    background-color: #033785;
    animation: myfirst 6s linear 0.2s infinite;
  }
}

.es,.gua {
  background: white;
  position:relative;
}

.gua{
  opacity: 0;
  z-index: 0;
  animation: cf3FadeOutIn 12s ease-in-out infinite;
}

.es {
  opacity: 1;
  z-index: 1;
  animation: cf3FadeInOut 12s ease-in-out infinite;
}

@keyframes cf3FadeOutIn {
  0% {
    height: 0%;
    opacity: 0;
  }
  50% {
    height: 0%;
    opacity: 0;

  }
  57% {
    opacity: 1;
    height: 100%;    
  }
  100% {
    opacity: 1;
    height: 100%;
  }
}

@keyframes cf3FadeInOut {
  0% {
    opacity: 1;
    height: 100%;
  }
  50% {
    opacity: 1;
    height: 100%;
  }
  55% {
    height: 0%;
    opacity: 0;
  }
  100% {
    height: 0%;
    opacity: 0;
  }
}



@keyframes myfirst {
  0% {
    width: 10%;
  }
  5% {
    left: 0%;
    width: 100%;
    height: 20px;
    opacity: 1;
  }
  10% {
    left: 50%;
    width: 10%;
    opacity: 1;
  }
  11% {
    left: 50%;
    width: 0%;
    opacity: 0;
  }
  100% {
    left: 50%;
    width: 0%;
    opacity: 0;
  }
}

@media screen and (max-width: 600px) {
  .flag {
    top: 75px;
    left: 0%;
  }
}
