.pswp__button--share {
  display: none !important;
}

.pswp-thumbnails {
  display: grid;
  column-gap: 16px;
  row-gap: 16px;
  grid-template-columns: auto auto auto auto;

  .pswp-thumbnail {
    display: inline-grid;
    margin: 0 10px 10px 0;
    cursor: pointer;
    img {
      max-width: 100%;
      height: 270px;
      width: 100%;
      display: block;
      object-fit: cover;
    }
  }
}
