.main-menu {
  .navbar-start {
    > a.navbar-item {
      color: white;
    }
  }
  .navbar-link {
    color: white;
  }

  .navbar-dropdown {
    background-color: white;
  }

  .navbar-link:not(.is-arrowless)::after {
    width: 0.525em;
    height: 0.525em;
    margin-top: -0.3375em;
    border-color: #fff !important;
  }
  &:hover {
    .menu {
      &.visible {
        display: block;
      }
    }
  }
  .navbar {
    .navbar-start {
      .navbar-item.hoverable:hover,
      .navbar-item.is-active,
      .navbar-link.is-active {
        background-color: $gris_gov !important;
        background: transparent url("../images/icono_gobierno.png") no-repeat;
        background-position: center left -7px;
        .svg-inline--fa {
          visibility: hidden;
        }
      }
    }
    .navbar-end {
      .buscador {
        width: 15rem;
      }
    }
  }

  .menu {
    position: relative;
    height: 1px;
    margin-top: -1px;
    overflow: visible;
    display: none;
    .menu-section {
      background: rgba($primary_active, 0.85);
      background: rgba($gris_gov, 0.95);
      width: 100%;
      z-index: 5000;
      position: relative;
      min-height: 250px;
      .menu-content {
        @extend .container;
        background: transparent;
        .menu-item {
          @extend .columns;
          margin-top: 0rem;
          color: $gris_claro_gov;
          &.is-visible {
            width: inherit;
          }
          .column {
            margin-top: 0.75rem;
            padding-right: 50px;
            border-right: 1px dashed rgba($gris_claro_gov, 0.3);
            &:last-child {
              border-right: 0px;
            }
            h4 {
              @extend .is-size-5;
              color: $gris_claro_gov;
              padding-bottom: 0.4rem;
              margin-bottom: 0.8rem;
              border-bottom: 2px solid $amarillo_gov;
            }
            li {
              padding: 0.2rem;
              cursor: pointer;
              a {
                color: $gris_claro_gov;
              }
              &:hover {
                background-color: rgba($celeste_gov, 0.3);
              }
            }
          }
        }
      }
    }
  }
}
