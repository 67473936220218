.pagina,
.is-pagina {
  font-size: 0.95rem;
  padding-top: 1.6rem;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7 {
    padding-top: 1.6rem;
    @extend .title;
  }
  h1 {
    @extend .is-3;
  }
  h2 {
    @extend .is-4;
  }
  h3 {
    @extend .is-5;
  }
  h4 {
    @extend .is-6;
  }
  h5 {
    @extend .is-7;
  }
}
.pagina {
  .pagina-md {
    display: table;
  }
  .pagina-content {
    display: block;
    p {
      margin-right: 15px;
      padding-bottom: 1.6rem;
      font-size: 0.95rem;
      text-align: justify;
    }

    img {
      &[alignment="center"] {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1.6rem;
      }
      &[alignment="right"] {
        float: right;
        margin-left: 1.6rem;
      }
      &[alignment="left"] {
        float: left;
        margin-right: 1.6rem;
      }
    }

    figure {
      position: relative;
      & + figure {
        img {
          &[alignment="right"] {
            margin-left: 2px;
          }
          &[alignment="left"] {
            margin-right: 2px;
          }
        }
      }
    }

    a[href] {
      @extend .is-link;
      font-style: italic;
    }
    .menu-list {
      a.is-active {
        background: $azul_siis !important;
      }
    }
  }
  ul,
  ol {
    margin-left: 1.6rem;
    padding-bottom: 1.6rem;
  }
  ul {
    list-style: disc;
  }

  .carousel {
    img {
      height: 100%;
      max-height: 450px;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
h7,
.title {
  color: $azul_gov;
}

iframe {
  width: 100%;
}

.galery {
  cursor: pointer;
  .title.is-6 {
    height: 2.1rem;
    overflow: hidden;
  }
}

.not-found {
  margin: 8rem auto;
}
