$primary:#1d3d1f;//#027223;
$rojo_gov:#8C0505;
$azul_gov:#465f6e;
$gris_gov:#373737;
$celeste_gov:#b9c3c8;
$gris_claro_gov:#ececec;
$text:hsl(0, 0%, 29%);

$azul-primary:rgb(0, 28, 84);
//$text
//$text-invert
//$text-light
$text-color:$text;//#5b676c;

$background:hsl(0, 0%, 96%);
$table-row-hover-background-color: hsl(0, 0%, 94%);



$amarillo_gov:#ff8a40;

$primary_active: darken($primary,5%);

$panel-heading-background-color: $primary;
$table-head-background-color:$primary;
$table-cell-heading-color:white;
$table-head-cell-color:white;
$table-foot-background-color:$gris_claro_gov;


$infocolor:#FF6384;

//$border
//$border-hover
//$border-light
//$border-light-hover



$size-1:3rem;
$size-2:2.5rem;
$size-3:2rem;
$size-4:1.45rem;
$size-5:1.20rem;
$size-6:0.95rem;
$size-7:0.75rem;
$body-font-size:$size-6;

$gris_siis:#b0cac9;
$naranja_siis:#f16537;
$azul_siis:#2e4e4d;
$primary_siis:#001A53;;

