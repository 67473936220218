.has-background {
  background-image: url("../images/home-fondo.jpg");
  background-repeat: round;
}
.is-512x512 {
  width: 512px;
}
.has-background-gov {
  background-color: $azul_gov;
}

.is-link {
  @extend .has-text-black;
  text-decoration: underline;
}

.is-link,
.is-link a {
  color: $azul_gov !important;
}

.is-invisible {
  display: none !important;
}
.is-visble {
  display: block;
}

.is-background-square {
  text-align: left;
  background: rgba($gris_claro_gov, 0.3) url("../images/background.png") repeat
    scroll;
}
hr {
  margin: 1rem 0;
}

.menu-list {
  list-style: none !important;
  ul {
    list-style: none !important;
  }
}

.is-hoverable {
  cursor: pointer;
}

.search-title {
  display: inline-block;
  padding: 10px 15px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
  cursor: pointer;
  color: $text;
  &:hover {
    color: #000;
  }
}

.search-title.active {
  color: #fff;
  background-color: $primary;
  border: 1px solid $primary;
  &:hover {
    background-color: rgba(2, 114, 35, 0.85);
    border: 1px solid rgba(2, 114, 35, 0.85);
  }
}

.logos {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  h4 {
    text-transform: uppercase;
    color: $text;
    background: $celeste_gov;
    height: 50px;
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    text-align: center;
    border-radius: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .logo {
    width: 100%;
    opacity: 1;
    padding: 5px;
    //margin-bottom: 0.5rem;
    &:last-child {
      margin-bottom: 1.5rem;
    }
    .image {
      width: 100%;
    }
    &:hover {
      opacity: 0.75;
      border-radius: 5px;
      background: $celeste_gov;
    }
  }
}

.banner-content {
  //padding: 25px;
  .legend {
    font-size: 1.2rem;
    text-align: justify;
  }
  .mas-info {
    margin-top: 30px;
    font-weight: bold;
  }
}

.banner .owl-item {
  //background-color: #e8e9eb;
  //border-radius: 10px;
  @extend .is-background-square;
  border: 0px solid $border;
}

.banner .owl-carousel {
  position: relative;
}
.pagina-content .carousel.carousel-slider {
  //height: 550px;
}

.banner .owl-theme .owl-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  bottom: 50%;
  z-index: -10;
  .owl-prev,
  .owl-next {
    position: relative;
    margin: 0px;
    width: 25px;
    font-size: 2rem;
    color: #707070;
    background: none;
    opacity: 0.75;
    &:hover {
      background: none;
      color: #444444;
    }
  }
  .owl-prev {
    right: 25px;
  }
  .owl-next {
    left: 25px;
  }
}

.navbar {
  background-color: #f5f5f5;
  .navbar-item {
    .navbar-link:not(.is-arrowless)::after {
      border-color: #4a4a4a;
    }
  }
}

@media screen and (max-width: 1023px) {
  .logos {
    justify-content: space-between;
    .logo {
      width: 30%;
    }
  }
}

@media screen and (max-width: 768px) {
  .banner-content {
    padding: 10px;
  }
  .logos {
    justify-content: space-between;
    .logo {
      width: 45%;
    }
  }
}

img {
  object-fit: cover;
}
