.card,
.box {
  border: 1px solid $border;
}
.news-box {
  @extend .card;
  .news-box-image {
    @extend .card-image;
  }
  .news-box-content {
    @extend .card-content;
    .title {
      min-height: 3.2rem;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .news-resume {
      @extend .is-size-7;
      text-align: justify;
      height: 6.7rem;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .is-time {
    cursor: unset;
  }
}
.news-list {
  .is-time {
    cursor: unset;
    &:hover {
      background-color: unset;
    }
  }
}

.fav-menu {
  .img {
    width: auto;
    margin-left: auto;
    margin-right: auto;
    //height: 7rem;
    text-align: center;
    img {
      height: inherit;
    }
  }
}

.fav-group {
  height: 21rem;
  .fav-menu {
    min-height: 230px;
    position: relative;
    cursor: pointer;
    margin-top: 2em;
    transform: translateY(-2em);
    z-index: 1;
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
      z-index 0s linear 0.01s;
    .fav-menu-content {
      display: none;
      opacity: 0;
    }
    &:hover {
      .fav-menu-content {
        display: block;
        opacity: 1;
      }
      opacity: 0.9;
      transform: translateY(0%);
      transition-delay: 0s, 0s, 0.3s;
    }
    hr {
      background: $celeste_gov;
    }
  }
  .fav-menu-content {
    li {
      border-top: 1px solid $border;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
    li:last-child {
      border-bottom: 1px solid $border;
    }
    li:hover {
      background: $background;
    }

    li a {
      //padding-left: 0.25rem;
      //padding-right: 0.25rem;
      color: $body-color;
      font-size: $body-font-size;
    }
  }
}

.video-box {
  .menu {
    min-height: 500px;
    overflow-y: auto;
    li {
      border-bottom: 1px solid $gris_claro_gov;
    }
    .is-active {
      background: $gris_gov !important;
    }
  }
}
