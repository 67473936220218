@import "variables";
@import "bulma/bulma";
@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "react-responsive-carousel/lib/styles/carousel.min";
@import "react-vertical-timeline-component/style.min";
@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "react-owl-carousel2/src/owl.carousel";
@import "react-owl-carousel2/src/owl.theme.default";
@import "header";
@import "footer";
@import "menu";
@import "custom";
@import "home";
@import "pagina";
@import "redes_sociales";
@import "timeline";
@import "collapsible";
@import "video";
@import "flags";
@import "loader";
@import "galeria";
@import "busqueda";
@import "line-clamp";

#chart-container {
  font-family: Arial;
  height: 420px;
  border: 2px dashed #aaa;
  border-radius: 5px;
  overflow: auto;
  text-align: center;
}