.redes-sociales {
  position: fixed;
  width: 30px;
  top: 45%;
  right: 0px;
  display: inline-grid;
  z-index: 100;
  a {
    font-size: 24px;
    padding: 0px;
    margin: 0px;
    height: 32px;
    width: 31px;
    color: white;
    text-align: center;
    &:hover{
        background-color: $celeste_gov;
    }
  }
  .facebook {
    background: #3b5998;
  }
  .twitter {
    background: #55acef;
  }
  .instagram {
    background: #dd2a7b;
  }
  .youtube {
    background: #e53e2f;
  }
  .flickr {
    background: #000000;
  }
  
}
