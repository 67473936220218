.timeline {
  ul {
    margin-left: 0rem;
  }
  @extend .is-background-square;
  .timeline-content {
    overflow-y: auto;
    overflow-x: hidden;
    //border-right: 2px solid $border;
  }
  .timeline-child {
    border-left: 2px solid white;
    .help {
      color: $text-light;
    }
  }
  .vertical-timeline-element {
    .vertical-timeline-element-icon {
      color: white;
      background-color: $azul_siis;
      svg {
        width: 1.5em !important;
      }
    }
  }
  .menu-list {
    padding-bottom: 0px;
    a.is-active {
      background: $azul_siis !important;
    }
  }
}
.vertical-timeline::before {
  background-color: $primary;
}

.vertical-timeline--two-columns {
  .vertical-timeline-element-content {
    width: 42%;
  }
  .vertical-timeline-element-content .vertical-timeline-element-date {
    left: 136%;
    font-style: italic;
  }
  .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left)
    .vertical-timeline-element-content
    .vertical-timeline-element-date {
    right: 136%;
  }

  .vertical-timeline-element {
    .vertical-timeline-element-content-arrow {
      border-right-color: $azul_siis;
      border-left-color: transparent;
    }
  }
  .vertical-timeline-element-content {
    cursor: pointer;
    box-shadow: 0 3px 0 $azul_siis !important;
    border-color: rgba($azul_siis, 0.4) !important;
    border-top: 1px solid;
    border-left: 1px solid;
    border-right: 1px solid;
  }
  .vertical-timeline-element-content:hover {
    background-color: rgba($azul_siis, 0.1) !important;
  }
  .is-active {
    .vertical-timeline-element-content {
      background-color: rgba($naranja_siis, 0.3) !important;
      box-shadow: 0 3px 0 $naranja_siis !important;
      border-color: rgba($naranja_siis, 0.4) !important;
    }
    .vertical-timeline-element-icon {
      background-color: $naranja_siis;
    }
  }
}

$timeline-indexes: 0, 1, 2, 3, 4;
$color-collection2: $azul_siis, $naranja_siis, $primary_siis, $gris_siis,
  $rojo_gov;

$color-collection: $naranja_siis, $naranja_siis, $naranja_siis, $naranja_siis,
  $naranja_siis;

@each $idx in $timeline-indexes {
  .timeline-el3-#{$idx} {
    cursor: pointer;
    .vertical-timeline-element-content {
      border-color: rgba(nth($color-collection, $idx + 1), 0.4) !important;
      border-top: 1px solid;
      border-left: 1px solid;
      border-right: 1px solid;
    }

    .vertical-timeline-element-content:hover {
      background-color: rgba(nth($color-collection, $idx + 1), 0.1) !important;
    }
    &.is-active {
      .vertical-timeline-element-content {
        background-color: rgba(
          nth($color-collection, $idx + 1),
          0.3
        ) !important;

        strong {
        }
      }
    }
    .vertical-timeline-element-content {
      box-shadow: 0 3px 0 nth($color-collection, $idx + 1) !important;
    }
    .vertical-timeline-element-icon {
      //background-color: nth($color-collection, $idx + 1) !important;
    }
  }
}
