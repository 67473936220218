.collapsible {
  margin-bottom: 0.2rem;
  border: 1px solid $border;
  .collapsible-head {
    cursor: pointer;
    padding: 0.75em 1em;
    background: #eee;
    font-weight: bold;
  }

  .icon {
    float: right;
  }
  .collapsible-content {
    padding-top: 1rem;
    overflow-y: hidden;
    .columns {
      margin-left: 0rem;
      margin-right: 0rem;
    }
  }

  &.open {
    .fa-angle-down {
      display: none;
    }
    .collapsible-content {
      display: block;
    }
    .fa-angle-up {
      display: inline-block;
    }
  }
  &.close {
    .fa-angle-down {
      display: inline-block;
    }
    .collapsible-content,
    .fa-angle-up {
      display: none;
    }
  }
}
