
  .lds-dual-ring {
    display: inline-block;
    width: 40px;
    height: 40px;
    &:after {
        content: " ";
        display: block;
        width: 32px;
        height: 32px;
        margin: 8px;
        border-radius: 50%;
        border: 4px solid #027223;
        border-color: #027223 transparent #027223 transparent;
        animation: lds-dual-ring 1.2s linear infinite;
      }
  }

  .loader-wrapper {
    width: 20%;
    margin: 5rem auto;
  }
  
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  